<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('客户管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['customer/add']" size="small">{{$t('新增客户')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div style="margin:15px 0; width: 250px;">
        <el-input :placeholder="$t('请输入客户名称')" v-model="name" clearable class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getUserList(1)"></el-button>
        </el-input>
      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('客户ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('客户名称')" prop="name" width="200">
        </el-table-column>
        <!-- <el-table-column label="客户手机号" prop="phone" width="160">
        </el-table-column> -->
        <el-table-column :label="$t('详细地址')" prop="detailed_address" width="300">
        </el-table-column>
        <!-- <el-table-column label="负责人id" prop="responsibility" width="100">
        </el-table-column> -->
        <el-table-column :label="$t('售后负责人')" prop="username" width="150">
        </el-table-column>
        <el-table-column :label="$t('销售负责人')" prop="xsusername" width="150">
        </el-table-column>
        <el-table-column :label="$t('商家二维码地址')" width="330">
         <template slot-scope="scope">
             <img :src="scope.row.yy_qrcode" width="100" height="100">
          </template>
        </el-table-column>
        <el-table-column :label="$t('创建时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="getDetail(scope)" v-if="user_role['customer/detail']">{{$t('查看详情')}}
            </el-button>
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['customer/edit']" size="small">{{$t('修改信息')}}
            </el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['customer/delete']" size="small">{{$t('删除客户')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加客户')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="right" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" required prop="name"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.name" :placeholder="$t('请输入')" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item :label="$t('固定电话')" prop="phone">
              <el-input v-model="addform.phone" :placeholder="$t('请输入')" clearable></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <!-- <el-col :span="12">
            <el-form-item label="编号" prop="number">
              <el-input v-model="addform.number" placeholder="请输入编号" clearable></el-input>
            </el-form-item> -->
          </el-col><!-- /编号 -->
          <el-col :span="12">
            <el-form-item :label="$t('地区')" prop="region">
              <el-input v-model="addform.region"></el-input>
            </el-form-item>
          </el-col><!-- /地区 -->
          <el-col :span="12">
            <el-form-item :label="$t('详细地址')" prop="detailed_address">
              <el-input v-model="addform.detailed_address"></el-input>
            </el-form-item>
          </el-col><!-- /详细地址 -->
          <el-col :span="12">
            <el-form-item :label="$t('售后负责人')" prop="responsibility"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.responsibility" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in perCharge" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /负责人id -->
          <el-col :span="12">
            <el-form-item :label="$t('销售负责人')" prop="seller">
              <el-select v-model="addform.seller" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in perCharge" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="addform.remarks"></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改客户信息')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="editform" label-position="left" label-width="100px" ref="editform">
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" required prop="name"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="editform.name" clearable></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item :label="$t('固定电话')" prop="phone">
              <el-input v-model="editform.phone" clearable></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <!-- <el-col :span="12">
            <el-form-item label="编号" prop="number">
              <el-input v-model="editform.number" clearable></el-input>
            </el-form-item>
          </el-col> -->
          <!-- /编号 -->
          <el-col :span="12">
            <el-form-item :label="$t('地区')" prop="region">
              <el-input v-model="editform.region"></el-input>
            </el-form-item>
          </el-col><!-- /地区 -->
          <el-col :span="12">
            <el-form-item :label="$t('详细地址')" prop="detailed_address">
              <el-input v-model="editform.detailed_address"></el-input>
            </el-form-item>
          </el-col><!-- /详细地址 -->
          <el-col :span="12">
            <el-form-item :label="$t('售后负责人')" prop="responsibility"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="editform.responsibility" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in perCharge" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col><!-- /负责人ID -->
          <el-col :span="12">
            <el-form-item :label="$t('销售负责人')" prop="seller">
              <el-select v-model="editform.seller" clearable :placeholder="$t('请选择')">
                <el-option v-for="item in perCharge" :key="item.id" :label="item.username" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="editform.remarks"></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('客户详情')" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form v-model="DetailForm" label-position="right" label-width="80px" ref="DetailForm">
          <el-col :span="12">
            <el-form-item :label="$t('客户ID')" prop="id">
              <el-input v-model="DetailForm.id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item :label="$t('客户名称')" prop="name">
              <el-input v-model="DetailForm.name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item :label="$t('固定电话')" prop="phone">
              <el-input v-model="DetailForm.phone" disabled></el-input>
            </el-form-item>
          </el-col><!-- /固定电话 -->
          <!-- <el-col :span="12">
            <el-form-item label="编号" prop="number">
              <el-input v-model="DetailForm.number" disabled></el-input>
            </el-form-item>
          </el-col> -->
          <!-- /编号 -->
          <el-col :span="12">
            <el-form-item :label="$t('地区')" prop="region">
              <el-input v-model="DetailForm.region" disabled></el-input>
            </el-form-item>
          </el-col><!-- /地区 -->
          <el-col :span="12">
            <el-form-item :label="$t('详细地址')" prop="detailed_address">
              <el-input v-model="DetailForm.detailed_address" disabled></el-input>
            </el-form-item>
          </el-col><!-- /详细地址 -->
          <el-col :span="12">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="DetailForm.remarks" disabled></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
          <el-col :span="24">
            <el-form-item :label="$t('联系人')" prop="remarks">
              <el-table :data="DetailForm.contact" height="300" style="width: 100%" border>
                <el-table-column :label="$t('联系人ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
                </el-table-column>
                <el-table-column :label="$t('联系人名称')" prop="username">
                </el-table-column>
                <el-table-column :label="$t('联系人手机号')" prop="phone">
                </el-table-column>
                <el-table-column :label="$t('联系人部门')" prop="department">
                </el-table-column>
                <el-table-column :label="$t('创建时间')" prop="created_at">
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col><!-- /备注 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">{{$t('关闭')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'QueryCustomer',
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      name: '',
      addform: {
        name: '',
        phone: '',
        number: '',
        region: '',
        detailed_address: '',
        remarks: '',
        responsibility: '',
        seller: ''
      },
      editform: {
        id: '',
        name: '',
        phone: '',
        number: '',
        region: '',
        detailed_address: '',
        remarks: '',
        responsibility: '',
        seller: ''
      },
      DetailForm: {
        id: '',
        name: '',
        phone: '',
        number: '',
        region: '',
        detailed_address: '',
        remarks: '',
        contact: []
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      perCharge: [],
      user_role: {}
    }
  },
  created () {
    this.getUserList()
    this.getAllMin()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        // console.log(item)
        this.editform = this.$common.forData2(item.row,this.editform)
        this.editform.seller = item.row.xsusername
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户列表
    getUserList (page, report) {
      let params = {
        page: page?page:this.Page.page,
        name: this.name
      }
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'customer/lists')
        return
      }
      this.$axios.post('customer/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // 添加客户
    addMin () {
      var params = {}; var key
      for (key in this.addform) {
        if (this.addform[key] != null && this.addform[key] != '') params[key] = this.addform[key]
      }
      this.$axios.post('customer/add', params).then((res) => {
        if (res.data.code === 0) {
          this.$store.dispatch('addSite',{oc_id:res.data.data.id,rname:params.name})
          this.getUserList()
          this.addAdmin = false
          var that = this
          this.$common.resetField('addform', that)
          this.$store.dispatch('getCid')
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改客户
    editMin () {
      var params = {}; var key
      for (key in this.editform) {
        if (this.editform[key] != null && this.editform[key] != '') params[key] = this.editform[key]
      }
      this.$axios.post('customer/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          this.$common.resetField('editform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 删除客户
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除该客户, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('customer/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$store.dispatch('getCid')
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else this.$message.warning(res.data.message)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取客户详情
    getDetail (scope) {
      // console.log(scope)
      this.$axios.post('customer/detail', {
        id: scope.row.id
      }).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          var data = res.data.data
          this.DetailForm = this.$common.forData2(data,this.DetailForm)
          this.DetailForm.contact = data.customer_contacts
          this.Detail = true
        } else this.$message.warning(res.data.message)
      }).catch((error) => console.log(error))
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    // 获取管理员
    getAllMin () {
      this.$axios.post('admin/getAll').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.perCharge = data.data
        } else this.$message.warning(data.message)
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
